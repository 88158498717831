<template>
  <modal
    v-if="show"
    title="Daten werden verarbeitet"
    :closable="false"
  >
    <template #body>
      <div class="form-row">
        <div class="col text-center">
          <i class="fal fa-file-code fa-3x text-gray-5 mb-3" />
          <span class="text-gray-5 mb-3 d-block">
            Antrag einreichen
          </span>
          <i
            v-if="xmlCreated"
            class="fas fa-check-circle fa-2x text-success"
          />
          <i
            v-else
            class="fas fa-spin fa-circle-notch fa-2x text-gray-4"
          />
        </div>
        <div
          v-if="order.status !== 0"
          class="col text-center"
        >
          <i class="fal fa-file-pdf fa-3x text-gray-5 mb-3" />
          <span class="text-gray-5 mb-3 d-block">
            Antrag generieren
          </span>
          <i
            v-if="pdfCreated"
            class="fas fa-check-circle fa-2x text-success"
          />
          <i
            v-else
            class="fas fa-spin fa-circle-notch fa-2x text-gray-4"
          />
        </div>
        <div class="col text-center">
          <i class="fal fa-envelope fa-3x text-gray-5 mb-3" />
          <span class="text-gray-5 mb-3 d-block">
            E-Mail verschicken
          </span>
          <i
            v-if="emailSent"
            class="fas fa-check-circle fa-2x text-success"
          />
          <i
            v-else
            class="fas fa-spin fa-circle-notch fa-2x text-gray-4"
          />
        </div>
      </div>
    </template>
    <template
      v-if="orderCreated"
      #footer
    >
      <div class="text-right">
        <button
          type="button"
          data-test="order-modal-close"
          class="btn btn-secondary mr-2"
          @click="$emit('close')"
        >
          Schließen
        </button>
        <SignedDownloadLink
          v-if="order.status !== 0 && pdf"
          :file="pdf.file"
        >
          <template #default="{link}">
            <a
              type="button"
              data-test="order-modal-download"
              :download="pdf.file.name"
              :href="link"
              class="btn btn-primary"
            >
              Download der Police
            </a>
          </template>
        </SignedDownloadLink>
      </div>
    </template>
  </modal>
</template>

<script>
import Modal from '@/components/Modal.vue'
import SignedDownloadLink from '@/components/SignedDownloadLink.vue'
export default {
  name: 'OrderModal',
  components: {
    Modal,
    SignedDownloadLink
  },
  props: {
    show: {
      type: Boolean,
      default: false
    },
    order: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    xmlCreated: false,
    pdfCreated: false,
    emailSent: false
  }),
  computed: {
    orderCreated () {
      return (this.order.status === 0 || this.pdfCreated) && this.xmlCreated && this.emailSent
    },
    pdf () {
      return this.order.pdfDocument
    },
    xml () {
      return this.order.xmlDocument
    }
  },
  watch: {
    xml: 'setXmlCreated',
    pdf: 'setPdfCreated',
    'order.email': 'setEmailSent',
    show: 'reset'
  },
  methods: {
    setXmlCreated (xmlDocument) {
      if (xmlDocument) this.xmlCreated = true
    },
    setPdfCreated (pdfDocument) {
      if (pdfDocument) this.pdfCreated = true
    },
    setEmailSent (email) {
      if (email) this.emailSent = true
    },
    reset (asd) {
      console.log(asd)
      this.xmlCreated = false
      this.pdfCreated = false
      this.emailSent = false
    }
  }
}
</script>
