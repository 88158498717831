var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ca-card',{staticClass:"mb-3",scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('div',{staticClass:"row"},[_c('h4',{staticClass:"mb-0 col"},[_vm._v(" "+_vm._s(_vm.title)+" ")])])]},proxy:true},{key:"body",fn:function(){return [_c('BasicRadio',{attrs:{"name":"Neuanlage","options":_vm.optionsNeuanlage,"required":"","inline":""},model:{value:(_vm.customerKind),callback:function ($$v) {_vm.customerKind=$$v},expression:"customerKind"}}),(_vm.customerKind === 'oldCustomer')?[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('basic-typeahead',{attrs:{"id":"customer-selection","name":"Kunde","label":"Kunde","columns":{
            label: ['col-12', 'col-md-4'],
            input: ['col-12', 'col-md-8'],
          },"horizontal":true,"auto-select-first":"","query":_vm.searchCustomerByName,"input-formatter":_vm.customerFormatter,"result-formatter":_vm.customerFormatter,"autocomplete":"off","margin":false,"required":"","strict":"","placeholder":"Nach Namen suchen"},on:{"hit":_vm.setCustomer,"input":_vm.delCustomer},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.customerSelected && !_vm.customOwner)?_c('div',[_c('hr'),_c('h5',[_vm._v("Versicherungsnehmer")]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 col-md-4"},[_vm._v(" Name ")]),_c('div',{staticClass:"col-12 col-md-8"},[_vm._v(" "+_vm._s(_vm.getFullNameFromCustomer(_vm.customer))+" ")]),_c('div',{staticClass:"col-12 col-md-4"},[_vm._v(" Straße, Nr. ")]),_c('div',{staticClass:"col-12 col-md-8"},[_vm._v(" "+_vm._s(_vm.customer.address.STR)+" ")]),_c('div',{staticClass:"col-12 col-md-4"},[_vm._v(" PLZ / Ort ")]),_c('div',{staticClass:"col-12 col-md-8"},[_vm._v(" "+_vm._s(_vm.customer.address.PLZ)+" "+_vm._s(_vm.customer.address.ORT)+" ")]),(_vm.customer && _vm.customer.misc && _vm.customer.misc.GEBDAT)?[_c('div',{staticClass:"col-12 col-md-4"},[_vm._v(" Geburtsdatum ")]),_c('div',{staticClass:"col-12 col-md-8"},[_vm._v(" "+_vm._s(_vm.moment(_vm.customer.misc.GEBDAT).format('DD.MM.YYYY'))+" ")])]:_vm._e(),_c('div',{staticClass:"col-12 col-md-4"},[_vm._v(" E-Mail Adresse ")]),_c('div',{staticClass:"col-12 col-md-8"},[_vm._v(" "+_vm._s(_vm.customer.contact.MAIL)+" ")])],2)]):_vm._e()]),_c('transition',{attrs:{"name":"slide-fade"}},[(_vm.customerSelected && _vm.customOwner)?_c('div',[_c('hr'),_c('div',{staticClass:"form-row my-3"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('basic-select',{attrs:{"id":"title-gen","data-test":"owner-form-title-gen","columns":{
                    label: ['col-12', 'col-md-8'],
                    input: ['col-12', 'col-md-4'],
                  },"placeholder":"Anrede","horizontal":true,"margin":false,"required":"","label":"Anrede","disabled":_vm.extend,"options":_vm.humanGenOptions},model:{value:(_vm.order.owner.title.gen),callback:function ($$v) {_vm.$set(_vm.order.owner.title, "gen", $$v)},expression:"order.owner.title.gen"}})],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('basic-select',{attrs:{"id":"title-tit","data-test":"owner-form-title-tit","margin":false,"placeholder":"Titel","disabled":_vm.extend,"options":_vm.humanTitOptions},model:{value:(_vm.order.owner.title.tit),callback:function ($$v) {_vm.$set(_vm.order.owner.title, "tit", $$v)},expression:"order.owner.title.tit"}})],1),_c('div',{staticClass:"col-12 col-md-3"},[_c('basic-select',{attrs:{"id":"title-pre","data-test":"owner-form-title-pre","margin":false,"placeholder":"Titel","disabled":_vm.extend,"options":_vm.humanPreOptions},model:{value:(_vm.order.owner.title.pre),callback:function ($$v) {_vm.$set(_vm.order.owner.title, "pre", $$v)},expression:"order.owner.title.pre"}})],1)]),(!_vm.isCompany)?[_c('basic-input',{attrs:{"id":"lastname","data-test":"owner-form-lastname","columns":{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                },"placeholder":"Nachname","horizontal":true,"disabled":_vm.extend,"label":"Nachname","required":""},model:{value:(_vm.order.owner.lastname),callback:function ($$v) {_vm.$set(_vm.order.owner, "lastname", $$v)},expression:"order.owner.lastname"}}),_c('basic-input',{attrs:{"id":"firstname","data-test":"owner-form-firstname","columns":{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                },"placeholder":"Vorname","horizontal":true,"disabled":_vm.extend,"label":"Vorname","required":""},model:{value:(_vm.order.owner.firstname),callback:function ($$v) {_vm.$set(_vm.order.owner, "firstname", $$v)},expression:"order.owner.firstname"}})]:_c('basic-input',{attrs:{"columns":{
                label: ['col-12', 'col-md-4'],
                input: ['col-12', 'col-md-8'],
              },"placeholder":"Firmenname","horizontal":true,"label":"Firma","required":""},model:{value:(_vm.order.owner.lastname),callback:function ($$v) {_vm.$set(_vm.order.owner, "lastname", $$v)},expression:"order.owner.lastname"}}),_c('basic-input',{attrs:{"id":"additional","data-test":"owner-form-additional","columns":{
                label: ['col-12', 'col-md-4'],
                input: ['col-12', 'col-md-8'],
              },"placeholder":"Zusatz","disabled":_vm.extend,"horizontal":true,"label":"Zusatz"},model:{value:(_vm.order.owner.additional),callback:function ($$v) {_vm.$set(_vm.order.owner, "additional", $$v)},expression:"order.owner.additional"}}),_c('basic-input',{attrs:{"id":"street","data-test":"owner-form-street","columns":{
                label: ['col-12', 'col-md-4'],
                input: ['col-12', 'col-md-8'],
              },"placeholder":"Straße, Nr.","horizontal":true,"label":"Straße, Nr.","required":""},model:{value:(_vm.order.owner.street),callback:function ($$v) {_vm.$set(_vm.order.owner, "street", $$v)},expression:"order.owner.street"}}),_c('div',{staticClass:"form-row my-3"},[_c('div',{staticClass:"col-12 col-md-6"},[(_vm.$system === 'at')?_c('basic-input',{attrs:{"id":"city-zip","data-test":"owner-form-city-zip","name":"PLZ","columns":{
                    label: ['col-12', 'col-md-8'],
                    input: ['col-12', 'col-md-4'],
                  },"placeholder":"PLZ","horizontal":true,"label":"PLZ / Ort","margin":false,"required":""},model:{value:(_vm.order.owner.city.zip),callback:function ($$v) {_vm.$set(_vm.order.owner.city, "zip", $$v)},expression:"order.owner.city.zip"}}):_c('basic-typeahead',{attrs:{"id":"city-zip","data-test":"owner-form-city-zip","name":"PLZ","auto-select-first":"","columns":{
                    label: ['col-12', 'col-md-8'],
                    input: ['col-12', 'col-md-4'],
                  },"placeholder":"PLZ","query":_vm.searchCityByZip,"input-formatter":function (city) { return city.zip.toString(); },"result-formatter":_vm.cityResultFormatter,"horizontal":true,"label":"PLZ / Ort","margin":false,"required":""},on:{"hit":_vm.setCity},model:{value:(_vm.order.owner.city.zip),callback:function ($$v) {_vm.$set(_vm.order.owner.city, "zip", $$v)},expression:"order.owner.city.zip"}})],1),_c('div',{staticClass:"col-12 col-md-6"},[(_vm.$system === 'at')?_c('basic-input',{attrs:{"id":"city-name","data-test":"owner-form-city-name","name":"Stadt","autocomplete":"off","margin":false,"required":"","placeholder":"Ort"},model:{value:(_vm.order.owner.city.name),callback:function ($$v) {_vm.$set(_vm.order.owner.city, "name", $$v)},expression:"order.owner.city.name"}}):_c('basic-typeahead',{attrs:{"id":"city-name","data-test":"owner-form-city-name","name":"Stadt","auto-select-first":"","query":_vm.searchCityByName,"input-formatter":function (city) { return city.name.toString(); },"result-formatter":_vm.cityResultFormatter,"autocomplete":"off","margin":false,"required":"","placeholder":"Ort"},on:{"hit":_vm.setCity},model:{value:(_vm.order.owner.city.name),callback:function ($$v) {_vm.$set(_vm.order.owner.city, "name", $$v)},expression:"order.owner.city.name"}})],1)]),_c('div',{staticClass:"form-row my-3"},[_c('div',{staticClass:"col-12 col-md-6"},[_c('basic-typeahead',{attrs:{"id":"country-char","data-test":"owner-form-country-char","name":"Land","columns":{
                    label: ['col-12', 'col-md-8'],
                    input: ['col-12', 'col-md-4'],
                  },"placeholder":"Land","horizontal":true,"label":"Land","margin":false,"query":_vm.searchCountry,"input-formatter":function (country) { return country.name.toString(); },"result-formatter":_vm.countryResultFormatter},on:{"input":function($event){return _vm.$delete(_vm.order.owner.country, 'name')},"hit":_vm.setCountry},model:{value:(_vm.order.owner.country.char),callback:function ($$v) {_vm.$set(_vm.order.owner.country, "char", $$v)},expression:"order.owner.country.char"}})],1),_c('div',{staticClass:"col-12 col-md-6"},[_c('basic-input',{attrs:{"id":"country-name","data-test":"owner-form-country-name","margin":false,"placeholder":"Land","disabled":""},model:{value:(_vm.order.owner.country.name),callback:function ($$v) {_vm.$set(_vm.order.owner.country, "name", $$v)},expression:"order.owner.country.name"}})],1)]),_c('basic-tel-input',{attrs:{"id":"mobile","data-test":"owner-form-mobile","columns":{
                label: ['col-12', 'col-md-4'],
                input: ['col-12', 'col-md-8'],
              },"horizontal":true,"label":"Mobiltelefonnummer","required":!_vm.order.owner.email},model:{value:(_vm.order.owner.mobile),callback:function ($$v) {_vm.$set(_vm.order.owner, "mobile", $$v)},expression:"order.owner.mobile"}}),_c('basic-input',{attrs:{"id":"phone","data-test":"owner-form-phone","columns":{
                label: ['col-12', 'col-md-4'],
                input: ['col-12', 'col-md-8'],
              },"placeholder":"Festnetznummer","horizontal":true,"label":"Festnetznummer"},model:{value:(_vm.order.owner.phone),callback:function ($$v) {_vm.$set(_vm.order.owner, "phone", $$v)},expression:"order.owner.phone"}}),_c('basic-input',{attrs:{"id":"email","data-test":"owner-form-email","columns":{
                label: ['col-12', 'col-md-4'],
                input: ['col-12', 'col-md-8'],
              },"placeholder":"E-Mail","horizontal":true,"label":"E-Mail","required":!_vm.order.owner.email},model:{value:(_vm.order.owner.email),callback:function ($$v) {_vm.$set(_vm.order.owner, "email", $$v)},expression:"order.owner.email"}}),_c('basic-datepicker',{attrs:{"id":"birthday","data-test":"owner-form-birthday","disabled":_vm.extend,"columns":{
                label: ['col-12', 'col-md-4'],
                input: ['col-12', 'col-md-8'],
              },"placeholder":"TT.MM.JJJJ","horizontal":true,"label":"Geburtstag"},model:{value:(_vm.order.owner.birthday),callback:function ($$v) {_vm.$set(_vm.order.owner, "birthday", $$v)},expression:"order.owner.birthday"}})],2):_vm._e()])],1)]:_vm._e(),(_vm.customerKind === 'newCustomer')?[_c('form',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();}}},[_c('CustomerForm',{attrs:{"customer":_vm.customer,"is-new-customer":_vm.customerKind === 'newCustomer'}}),_c('ContactForm',{attrs:{"customer":_vm.customer}})],1)]:_vm._e(),(_vm.isHardknockoutGEBDAT)?_c('Hardknockout',{attrs:{"field-name":"Geburtstag"}}):_vm._e(),(_vm.isHardknockoutNeuabschlussGesperrt)?_c('Hardknockout',{attrs:{"message":"Der Neuabschluss von Verträgen ist für diesen Kunden gesperrt"}}):_vm._e(),(_vm.$can('seeSanctionCheckResults'))?[(_vm.isUberpruefungSanctions)?_c('Ueberpruefung',{attrs:{"message":"Der Kunde befindet sich auf der Sanktionsliste der EU"}}):_vm._e(),(_vm.hasSanctions)?_c('BasicCheckbox',{staticClass:"mb-4",attrs:{"id":"overwriteSanctions","name":"Sanktionen überprüfen","required":_vm.order.hsOrderType === 'ANFRAGE',"label":"Ich habe den Sanktionslisteneintrag für den Kunden manuell überprüft, und bestätige hiermit, dass es sich  bei dem Ergebnis um eine Verwechslung handeln muss."},model:{value:(_vm.order._overwriteSanctions),callback:function ($$v) {_vm.$set(_vm.order, "_overwriteSanctions", $$v)},expression:"order._overwriteSanctions"}}):_vm._e()]:_vm._e()]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }