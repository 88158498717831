<template>
  <div class="bg-danger-light text-danger py-1 px-2 rounded">
    <h5 class="mb-0">
      Achtung
    </h5>
    <template v-if="fieldName">
      Wegen des eingegebenen Wertes zu dem Feld "{{ fieldName }}", können Sie keinen Antrag/Vertrag erstellen.
    </template>
    <template v-else>
      {{ message }}
    </template>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Die Voraussetzungen für die Versicherung als Liebhaberfahrzeug sind nicht erfüllt. Leider können wir Ihnen kein Angebot unterbreiten.'
    },
    fieldName: {
      type: String,
      default: null
    }
  }
}
</script>
