<template>
  <ca-card
    v-if="$can('seePolicyDebugInformation')"
    class="mb-3"
  >
    <template #header>
      <div class="row">
        <div class="col">
          <h4 class="mb-0">
            Entwicklerinformationen
          </h4>
        </div>
      </div>
    </template>
    <template #body>
      <details
        v-for="(item, key) in data"
        :key="key"
      >
        <summary>{{ key }}</summary>
        <pre>{{ item }}</pre>
      </details>
      <slot name="button" />
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card'
export default {
  name: 'DevInfo',
  components: {
    CaCard
  },
  props: {
    data: {
      type: Object,
      required: true
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
