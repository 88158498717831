<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Produkt / Tarif
      </h4>
    </template>
    <template #body>
      <form
        novalidate
        @submit.prevent="save"
      >
        <div class="col-4" />
        <basic-select
          v-model="selectedProductHn"
          data-test="product-form-product"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :placeholder="!edit && rates && rates.length === 0 ? 'Kein Tarif zu den Kriterien gefunden' : 'bitte wählen'"
          :disabled="!edit && rates && rates.length === 0"
          :horizontal="true"
          label="Produkt"
          :options="productOptions"
          required
          @input="onProductSelect"
        />

        <basic-select
          v-if="!endDateSelectable"
          v-model="selectedLaufzeit"
          data-test="product-form-laufzeit"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="bitte wählen"
          :horizontal="true"
          label="Laufzeit"
          :options="laufzeitOptions"
          :disabled="!selectedProductHn || (edit && (ratesPending || rates && rates.length === 0))"
          :small="ratesPending ? 'Tarife werden geladen...' : ''"
          required
          @input="onLaufzeitSelect"
        />

        <basic-select
          :value="order.product.rateId"
          data-test="product-form-rate"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="bitte wählen"
          :horizontal="true"
          label="Tarif"
          :options="rateOptions"
          :disabled="!selectedLaufzeit && (!endDateSelectable || !selectedProductHn)"
          required
          @input="setRate"
        />
        <taxi-form :order="order" />
        <basic-datepicker
          id="beginn"
          v-model="order.product.beginContractDate"
          data-test="product-form-begin-contract-date"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          :disabled-dates="disabledDates"
          label="Beginn"
          :disabled="extend"
          required
          placeholder="TT.MM.JJJJ"
        />
        <b-popover
          target="beginn"
          title="Garantiebeginn"
          placement="top"
          triggers="hover focus"
          content="Bitte geben Sie den genauen Garantiebeginn ein. Garantiebeginn bei Fahrzeugauslieferung oder direkt nach Ablauf der Herstellergarantie"
        />
        <basic-datepicker
          v-if="order.productType === 'SORGLOS_FLAT'"
          id="manufacturerWarrantyEnd"
          v-model="order.manufacturerWarrantyEnd"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Ende der Herstellergarantie"
          :disabled="extend"
          required
          placeholder="TT.MM.JJJJ"
        />
        <basic-datepicker
          v-if="endDateSelectable"
          id="ende"
          v-model="order.product.endContractDate"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          label="Ende"
          :disabled="extend"
          required
          placeholder="TT.MM.JJJJ"
        />
        <div
          v-if="order.productType === 'SORGLOS_FLAT'"
          class="form-row mb-3"
        >
          <div class="col-12 col-md-4">
            Laufzeit
          </div>
          <div class="col-12 col-md-5">
            <span class="font-weight-bold">
              {{ customLaufzeit }} Tage
            </span>
          </div>
        </div>
        <basic-input
          v-if="order.product.rate && order.product.rate.rateType === 'GKV' && !noUserProvision"
          v-model="order.product.userProvisionPercent"
          type="number"
          label="Aufschlag in %"
          min="0"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
        />
        <template v-if="edit">
          <basic-input
            v-model="priceModifier.priceModifierValue"
            :disabled="!priceModifierEditable"
            type="number"
            label="Rabatt / Aufschlag"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
          >
            <template #prepend>
              <basic-select
                v-model="priceModifier.priceModifierType"
                :margin="false"
                :disabled="!priceModifierEditable"
              >
                <option
                  value="DISCOUNT"
                >
                  <span>Rabatt</span>
                </option>
                <option
                  value="SURCHARGE"
                >
                  <span>Aufschlag</span>
                </option>
              </basic-select>
            </template>
            <template #append>
              <basic-select
                v-model="priceModifier.priceModifierUnit"
                :margin="false"
                :disabled="!priceModifierEditable"
              >
                <option
                  value="PERCENT"
                >
                  <span>%</span>
                </option>
                <option
                  value="EURO"
                >
                  <span>€</span>
                </option>
              </basic-select>
            </template>
            <template v-if="order.mobilityRate">
              <basic-datepicker
                v-model="order.mobilityRate.start"
                :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
                :horizontal="true"
                label="Beginn der Mobilitätsgarantie"
                required
                placeholder="TT.MM.JJJJ"
              />

              <div class="form-row mb-3">
                <div class="col-12 col-md-4">
                  <span>Netto</span>
                </div>
                <div class="col-12 col-md-8">
                  <span class="font-weight-bold">
                    <span>
                      {{ order.mobilityRate.netto | formatPrice }}
                    </span>
                  </span>
                </div>
              </div>

              <div class="form-row mb-3">
                <div class="col-12 col-md-4">
                  <span>Brutto</span>
                </div>
                <div class="col-12 col-md-8">
                  <span class="font-weight-bold">
                    <span>
                      {{ order.mobilityRate.brutto | formatPrice }}
                    </span>
                  </span>
                </div>
              </div>
            </template>
          </basic-input>
        </template>
        <div class="form-row mb-3">
          <div class="col-12 col-md-4">
            <span>Basispreis</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate && order.product.rate.brutto">
                {{ netto | formatPrice }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>
        <div
          v-if="order.product.rate && order.product.rate.rateType === 'GKV' && !noUserProvision"
          class="form-row mb-3"
        >
          <div class="col-12 col-md-4">
            <span>Aufschlag</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate && order.product.rate.brutto">
                {{ priceAufschlag | formatPrice }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>

        <div
          v-if="order.product.rate && order.product.rate.rateType === 'GKV'"
          class="form-row mb-3"
        >
          <div class="col-12 col-md-4">
            <span>Versicherungsteuer (19%)</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate && order.product.rate.brutto">
                {{ tax | formatPrice }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>

        <div class="form-row mb-3">
          <div class="col-12 col-md-4">
            <span>Bruttoprämie</span>
          </div>
          <div class="col-12 col-md-8">
            <span class="font-weight-bold">
              <span v-if="order.product.rate && order.product.rate.brutto">
                {{ brutto | formatPrice }}
              </span>
              <span v-else>
                -
              </span>
            </span>
          </div>
        </div>

        <basic-select
          v-model="gbMethod"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="bitte wählen"
          :horizontal="true"
          label="Übergabe GB-A/GB-B "
          :options="humanGbOptions"
          :disabled="!selectedProductHn || (edit && (ratesPending || rates && rates.length === 0))"
          required
        />

        <template v-if="!noConditionsChecks">
          <basic-checkbox
            v-model="order.gba"
            data-test="product-form-gba"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            name="GB-A/GB-B"
            :required="extend || rates && rates.length > 0 && !order.car.noHsnTsn"
            :disabled="!(edit || extend || rates && rates.length > 0 && !order.car.noHsnTsn)"
            label="Die GB-A/GB-B werden vom Garantiegeber/Versicherungsnehmer ausgedruckt und dem Käufer/Halter ausgehändigt."
          />

          <basic-checkbox
            v-model="order.isHandedOut"
            data-test="product-form-is-handed-out"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            name="Vertrag ausdruck"
            :required="extend || rates && rates.length > 0 && !order.car.noHsnTsn"
            :disabled="!(edit || extend || rates && rates.length > 0 && !order.car.noHsnTsn)"
            label="Der Vertrag wird vom Garantiegeber/Versicherungsnehmer ausgedruckt und dem Käufer/Halter ausgehändigt."
          />
        </template>
        <!-- <basic-checkbox
          v-if="me && (me.role == 1 || me.role == 10) && order.product.rate && order.product.rate.GVCfrequency && order.product.rate.GVCfrequency.length != 0"
          v-model="order.notification"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          :horizontal="true"
          name="GVC Benachrichtigung"
          label="Halter erhält Benachrichtigung zum GVC"
        /> -->
        <template v-if="mobilityRates && mobilityRates.length > 0 && !edit && order.product.rate && order.product.rate.rateType !== 'TAXI' && order.product.rate.rateType !== 'MOBILITY'">
          <basic-checkbox
            v-model="order.mobility"
            :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
            :horizontal="true"
            label="Zusätzlich Mobilitätsgarantie abschließen"
          />
          <template v-if="order.mobility">
            <basic-select
              :value="order.mobilityRateId"
              :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
              placeholder="bitte wählen"
              :horizontal="true"
              label="Tarifauswahl"
              :options="mobilityRateOptions"
              required
              @input="setMobilityRate"
            />
          </template>
          <ca-alert
            v-if="order.product.rate && ((order.product.rate.extendRateIds && order.product.rate.extendRateIds.length != 0) || (order.product.rate.GVCfrequency > 0)) && (!order.owner.email && !order.owner.mobile)"
            variant="danger"
            message="E-Mailadresse oder Mobiltelefonnummer werden zwingend zum Vertragsabschluss benötigt."
            not-closeable
          />
        </template>
      </form>
    </template>
  </ca-card>
</template>

<script>
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'
import BasicInput from '@/components/BaseComponents/BasicInput'
import CaCard from '@/components/Card'
import TaxiForm from './TaxiForm'
import formatPrice from '../../../filters/formatPrice.js'
import sendGbMethod from '@/resources/enums/sendGbMethod.js'
import moment from 'moment'

function round (num) {
  return Math.round(num * 100) / 100
}

export default {
  inject: ['$validator'],
  name: 'ProductForm',
  components: {
    BasicSelect,
    BasicDatepicker,
    BasicCheckbox,
    BasicInput,
    CaCard,
    TaxiForm
  },
  filters: {
    formatPrice
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    rates: {
      type: Array,
      default: () => []
    },
    ratesPending: {
      type: Boolean,
      default: false
    },
    products: {
      type: Array,
      default: () => []
    },
    mobilityRates: {
      type: Array,
      default: () => []
    },
    extend: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    preSelectedProductHn: {
      type: String,
      default: null
    },
    noConditionsChecks: {
      type: Boolean,
      default: true
    },
    noUserProvision: {
      type: Boolean,
      default: false
    },
    endDateSelectable: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    selectedProductHn: undefined,
    selectedLaufzeit: undefined,
    priceModifier: {
      priceModifierValue: undefined,
      priceModifierType: undefined,
      priceModifierUnit: undefined
    },
    prevStatus: null,
    gbMethod: null,
    sendGbMethod
  }),
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    },
    humanGbOptions () {
      return Object.values(this.sendGbMethod).map(method => ({ label: method.human, value: method.api }))
    },
    productOptions () {
      if (!this.edit) {
        const productOptions = this.rates
          .reduce((products, rate) => {
            if (products.every(product => product.hn !== rate.product.hn)) {
              products.push(rate.product)
            }
            return products
          }, [])
          .map(product => ({
            label: `(${product.hn}) ${product.name}`,
            value: product.hn
          }))
        if (this.ratesPending) {
          productOptions.push({
            label: 'Weitere Produkte werden geladen...',
            disabled: true
          })
        }
        return productOptions
      } else {
        return this.products
          .map(product => ({
            label: `(${product.hn}) ${product.name}`,
            value: product.hn
          }))
      }
    },
    laufzeitOptions () {
      return this.rates.filter(rate => rate.product && this.selectedProductHn && rate.product.hn.toString() === this.selectedProductHn.toString())
        .reduce((laufzeiten, rate) => {
          if (!laufzeiten.includes(rate.laufzeit)) {
            laufzeiten.push(rate.laufzeit)
          }
          return laufzeiten
        }, [])
        .map(laufzeit => ({
          label: `${laufzeit} Monate`,
          value: laufzeit
        }))
    },
    rateOptions () {
      return this.rates.filter(rate => {
        return rate.product && this.selectedProductHn && rate.product.hn.toString() === this.selectedProductHn.toString() &&
               ((this.selectedLaufzeit && rate.laufzeit === this.selectedLaufzeit) || (this.endDateSelectable && rate.laufzeit === 12))
      })
        .map(rate => ({
          label: `${rate.name}`,
          value: rate._id.toString()
        }))
    },
    mobilityRateOptions () {
      return this.mobilityRates.map(mobilityRate => ({
        id: mobilityRate._id,
        label: `${mobilityRate.name}`,
        value: mobilityRate._id
      }))
    },
    priceModifierEditable () {
      // Anfrage zu Vertrag
      return this.prevStatus === 0 && this.order.status === 1
    },
    priceModifierMultiplier () {
      const rate = this.order.product.rate
      let modifier
      if (!this.edit) {
        modifier = this.getUserPriceModifier(rate)
      } else {
        modifier = this.getPriceModifier(this.priceModifier, rate.brutto)
      }
      return (1 + modifier)
    },
    taxPercent () {
      return this.$system === 'at' ? 0.11 : 0.19
    },
    netto () {
      const upchargeModifier = this.getUpcharge()
      return round(this.order.product.rate.brutto * this.priceModifierMultiplier * upchargeModifier / (1 + this.taxPercent))
    },
    provisionModifier () {
      if (!this.order.product.userProvisionPercent) return 0
      const provisionModifier = this.order.product.userProvisionPercent / 100
      return provisionModifier
    },
    priceAufschlag () {
      return round(this.netto * this.provisionModifier)
    },
    tax () {
      const tax = this.$system === 'at' ? 0.11 : 0.19
      return round(this.brutto * (1 - 1 / (1 + tax)))
    },
    brutto () {
      const upchargeModifier = this.getUpcharge()
      let brutto = this.order.product.rate.brutto
      if (this.endDateSelectable) {
        // the brutto price is the yearly price
        const dayPrice = round(brutto / 365)
        brutto = this.customLaufzeit * dayPrice
      }
      brutto = round(brutto * this.priceModifierMultiplier * upchargeModifier * (1 + this.provisionModifier))
      return brutto
    },
    disabledDates () {
      if (!this.edit) {
        return { to: new Date(moment().subtract(30, 'days')) }
      } else {
        return {}
      }
    },
    customLaufzeit () {
      const startDate = this.order.productType === 'SORGLOS_FLAT' ? this.order.manufacturerWarrantyEnd : this.order.product.startContractDate
      const endDate = this.order.product.endContractDate
      return moment(endDate).startOf('day').diff(moment(startDate).startOf('day'), 'days')
    }
  },
  watch: {
    rates: {
      handler () {
        this.preselectRate()
        if (this.preSelectedProductHn && !this.selectedProductHn) {
          this.selectedProductHn = this.preSelectedProductHn
        }
      }
    },
    gbMethod: {
      handler () {
        if (this.gbMethod === this.sendGbMethod.MAIL.api) {
          this.noConditionsChecks = true
        } else {
          this.noConditionsChecks = false
        }
      }
    }
  },
  created () {
    if (this.edit) {
      if (this.order.product.rate && this.order.product.rate.un && this.order.product.rate.product && this.order.product.rate.product.hn) {
        const rate = this.rates.find(rate => rate.un.toString() === this.order.product.rate.un.toString() && rate.product.hn.toString() === this.order.product.rate.product.hn.toString())
        if (rate) {
          this.$set(this.order.product.rate, '_id', rate._id.toString())
          this.$set(this.order.product, 'rateId', rate._id.toString())
          this.selectedProductHn = this.order.product.rate.product.hn
          this.selectedLaufzeit = this.order.product.rate.laufzeit
        }
      }
      this.prevStatus = this.order.status
      this.loadOrderPriceModifier()
    } else {
      if (this.$route.params.rateId !== undefined) {
        this.setRate(this.$route.params.rateId)
      }
    }
  },
  methods: {
    reset () {
      this.selectedProductHn = undefined
      this.selectedLaufzeit = undefined
      this.$set(this.order.product, 'rate', undefined)
      this.$set(this.order.product, 'rateId', undefined)
    },
    setRate (rateId) {
      const rate = this.rates.find(rate => rate._id.toString() === rateId.toString())
      this.$set(this.order.product, 'rateId', rateId)
      this.$set(this.order.product, 'rate', rate)
      this.$set(this.order.product, 'userProvisionPercent', 0)
      if (rate.rateType === 'GKV') {
        // this.order.product.userProvisionPercent = 0
      }
    },
    setMobilityRate (mobilityRateId) {
      const mobilityRate = this.mobilityRates.find(mobilityRate => mobilityRate._id === mobilityRateId)
      this.$set(mobilityRate, 'start', new Date())
      this.order.mobilityRateId = mobilityRateId
      this.order.mobilityRate = mobilityRate
    },
    getUpcharge () {
      if (this.order.car.gasUpcharge && this.order.product.rate && this.order.product.rate.rateType !== 'MOBILITY') {
        return 1.3
      } else {
        return 1
      }
    },
    onProductSelect () {
      this.selectedLaufzeit = undefined
      this.$set(this.order.product, 'rate', undefined)
      this.$set(this.order.product, 'rateId', undefined)
      if (this.edit) {
        const product = this.products.find(product => product.hn === this.selectedProductHn)
        this.$emit('productSelected', product._id)
      }
    },
    onLaufzeitSelect () {
      this.$set(this.order.product, 'rate', undefined)
      this.$set(this.order.product, 'rateId', undefined)
    },
    getUserPriceModifier (selectedRate) {
      const productPriceModifier = this.me.products.find(product => product.productId === selectedRate.productId)
      if (!productPriceModifier) return 0
      const ratePriceModifier = (productPriceModifier.rates || []).find(rate => rate.rateId === selectedRate._id)
      return this.getPriceModifier(ratePriceModifier, selectedRate.brutto) || this.getPriceModifier(productPriceModifier, selectedRate.brutto)
    },
    getPriceModifier (priceModifierConf, brutto) {
      if (!priceModifierConf || !priceModifierConf.priceModifierValue) return 0
      let priceModifier
      switch (priceModifierConf.priceModifierUnit) {
      case 'PERCENT': {
        priceModifier = priceModifierConf.priceModifierValue / 100
        break
      }
      case 'EURO': {
        // get percent value for number for netto calculation
        priceModifier = priceModifierConf.priceModifierValue / brutto
        break
      }
      }
      return (priceModifierConf.priceModifierType === 'SURCHARGE' ? 1 : -1) * priceModifier
    },
    loadOrderPriceModifier () {
      let priceModifierValue = 0
      let priceModifierType = 'DISCOUNT'
      let priceModifierUnit = 'PERCENT'
      if (this.order.product && (this.order.product.userPriceModifierPercent || this.order.product.userPriceModifierEuro)) {
        let value
        if (this.order.product.userPriceModifierPercent) {
          value = this.order.product.userPriceModifierPercent
          priceModifierUnit = 'PERCENT'
        } else {
          value = this.order.product.userPriceModifierEuro
          priceModifierValue = this.order.product.userPriceModifierEuro
          priceModifierUnit = 'EURO'
        }
        priceModifierValue = value
        priceModifierType = this.order.product.userPriceModifierBrutto > 0 ? 'SURCHARGE' : 'DISCOUNT'
      }
      this.priceModifier = {
        priceModifierValue,
        priceModifierType,
        priceModifierUnit
      }
    },
    preselectRate () {
      if (this.rates.length === 1) {
        const rate = this.rates[0]
        this.$set(this.order.product, 'rate', rate)
        this.$set(this.order.product, 'rateId', rate._id.toString())
        this.selectedProductHn = rate.product.hn
        this.selectedLaufzeit = rate.laufzeit
      } else if (this.productOptions.length === 1) {
        this.selectedProductHn = this.productOptions[0].value
      }
    }
  }
}
</script>
