<template>
  <ca-card class="mb-3">
    <template #header>
      <div class="row">
        <h4 class="mb-0 col">
          {{ title }}
        </h4>
      </div>
    </template>
    <template #body>
      <BasicRadio
        v-model="customerKind"
        name="Neuanlage"
        :options="optionsNeuanlage"
        required
        inline
      />
      <template v-if="customerKind === 'oldCustomer'">
        <form
          novalidate
          @submit.prevent
        >
          <basic-typeahead
            id="customer-selection"
            v-model="customer"
            name="Kunde"
            label="Kunde"
            :columns="{
              label: ['col-12', 'col-md-4'],
              input: ['col-12', 'col-md-8'],
            }"
            :horizontal="true"
            auto-select-first
            :query="searchCustomerByName"
            :input-formatter="customerFormatter"
            :result-formatter="customerFormatter"
            autocomplete="off"
            :margin="false"
            required
            strict
            placeholder="Nach Namen suchen"
            @hit="setCustomer"
            @input="delCustomer"
          />
          <transition name="slide-fade">
            <div v-if="customerSelected && !customOwner">
              <hr>
              <h5>Versicherungsnehmer</h5>
              <div class="row mb-3">
                <div class="col-12 col-md-4">
                  Name
                </div>
                <div class="col-12 col-md-8">
                  {{ getFullNameFromCustomer(customer) }}
                </div>
                <div class="col-12 col-md-4">
                  Straße, Nr.
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.address.STR }}
                </div>
                <div class="col-12 col-md-4">
                  PLZ / Ort
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.address.PLZ }} {{ customer.address.ORT }}
                </div>
                <template v-if="customer && customer.misc && customer.misc.GEBDAT">
                  <div class="col-12 col-md-4">
                    Geburtsdatum
                  </div>
                  <div class="col-12 col-md-8">
                    {{ moment(customer.misc.GEBDAT).format('DD.MM.YYYY') }}
                  </div>
                </template>
                <div class="col-12 col-md-4">
                  E-Mail Adresse
                </div>
                <div class="col-12 col-md-8">
                  {{ customer.contact.MAIL }}
                </div>
              </div>
            </div>
          </transition>
          <!-- <div v-if="customerSelected">
            <hr>
            <div class="form-row">
              <div class="col-12 col-md-4">
                Abweichender Halter
              </div>
              <div class="col-12 col-md-8 d-flex align-items-center">
                <BasicCheckbox
                  v-model="customOwner"
                  :margin="false"
                />
              </div>
            </div>
          </div> -->
          <transition name="slide-fade">
            <div v-if="customerSelected && customOwner">
              <hr>
              <div class="form-row my-3">
                <div class="col-12 col-md-6">
                  <basic-select
                    id="title-gen"
                    v-model="order.owner.title.gen"
                    data-test="owner-form-title-gen"
                    :columns="{
                      label: ['col-12', 'col-md-8'],
                      input: ['col-12', 'col-md-4'],
                    }"
                    placeholder="Anrede"
                    :horizontal="true"
                    :margin="false"
                    required
                    label="Anrede"
                    :disabled="extend"
                    :options="humanGenOptions"
                  />
                </div>
                <div class="col-12 col-md-3">
                  <basic-select
                    id="title-tit"
                    v-model="order.owner.title.tit"
                    data-test="owner-form-title-tit"
                    :margin="false"
                    placeholder="Titel"
                    :disabled="extend"
                    :options="humanTitOptions"
                  />
                </div>
                <div class="col-12 col-md-3">
                  <basic-select
                    id="title-pre"
                    v-model="order.owner.title.pre"
                    data-test="owner-form-title-pre"
                    :margin="false"
                    placeholder="Titel"
                    :disabled="extend"
                    :options="humanPreOptions"
                  />
                </div>
              </div>
              <template v-if="!isCompany">
                <basic-input
                  id="lastname"
                  v-model="order.owner.lastname"
                  data-test="owner-form-lastname"
                  :columns="{
                    label: ['col-12', 'col-md-4'],
                    input: ['col-12', 'col-md-8'],
                  }"
                  placeholder="Nachname"
                  :horizontal="true"
                  :disabled="extend"
                  label="Nachname"
                  required
                />
                <basic-input
                  id="firstname"
                  v-model="order.owner.firstname"
                  data-test="owner-form-firstname"
                  :columns="{
                    label: ['col-12', 'col-md-4'],
                    input: ['col-12', 'col-md-8'],
                  }"
                  placeholder="Vorname"
                  :horizontal="true"
                  :disabled="extend"
                  label="Vorname"
                  required
                />
              </template>
              <basic-input
                v-else
                v-model="order.owner.lastname"
                :columns="{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                }"
                placeholder="Firmenname"
                :horizontal="true"
                label="Firma"
                required
              />
              <basic-input
                id="additional"
                v-model="order.owner.additional"
                data-test="owner-form-additional"
                :columns="{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                }"
                placeholder="Zusatz"
                :disabled="extend"
                :horizontal="true"
                label="Zusatz"
              />
              <basic-input
                id="street"
                v-model="order.owner.street"
                data-test="owner-form-street"
                :columns="{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                }"
                placeholder="Straße, Nr."
                :horizontal="true"
                label="Straße, Nr."
                required
              />
              <div class="form-row my-3">
                <div class="col-12 col-md-6">
                  <basic-input
                    v-if="$system === 'at'"
                    id="city-zip"
                    v-model="order.owner.city.zip"
                    data-test="owner-form-city-zip"
                    name="PLZ"
                    :columns="{
                      label: ['col-12', 'col-md-8'],
                      input: ['col-12', 'col-md-4'],
                    }"
                    placeholder="PLZ"
                    :horizontal="true"
                    label="PLZ / Ort"
                    :margin="false"
                    required
                  />
                  <basic-typeahead
                    v-else
                    id="city-zip"
                    v-model="order.owner.city.zip"
                    data-test="owner-form-city-zip"
                    name="PLZ"
                    auto-select-first
                    :columns="{
                      label: ['col-12', 'col-md-8'],
                      input: ['col-12', 'col-md-4'],
                    }"
                    placeholder="PLZ"
                    :query="searchCityByZip"
                    :input-formatter="(city) => city.zip.toString()"
                    :result-formatter="cityResultFormatter"
                    :horizontal="true"
                    label="PLZ / Ort"
                    :margin="false"
                    required
                    @hit="setCity"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <basic-input
                    v-if="$system === 'at'"
                    id="city-name"
                    v-model="order.owner.city.name"
                    data-test="owner-form-city-name"
                    name="Stadt"
                    autocomplete="off"
                    :margin="false"
                    required
                    placeholder="Ort"
                  />
                  <basic-typeahead
                    v-else
                    id="city-name"
                    v-model="order.owner.city.name"
                    data-test="owner-form-city-name"
                    name="Stadt"
                    auto-select-first
                    :query="searchCityByName"
                    :input-formatter="(city) => city.name.toString()"
                    :result-formatter="cityResultFormatter"
                    autocomplete="off"
                    :margin="false"
                    required
                    placeholder="Ort"
                    @hit="setCity"
                  />
                </div>
              </div>
              <div class="form-row my-3">
                <div class="col-12 col-md-6">
                  <basic-typeahead
                    id="country-char"
                    v-model="order.owner.country.char"
                    data-test="owner-form-country-char"
                    name="Land"
                    :columns="{
                      label: ['col-12', 'col-md-8'],
                      input: ['col-12', 'col-md-4'],
                    }"
                    placeholder="Land"
                    :horizontal="true"
                    label="Land"
                    :margin="false"
                    :query="searchCountry"
                    :input-formatter="(country) => country.name.toString()"
                    :result-formatter="countryResultFormatter"
                    @input="$delete(order.owner.country, 'name')"
                    @hit="setCountry"
                  />
                </div>
                <div class="col-12 col-md-6">
                  <basic-input
                    id="country-name"
                    v-model="order.owner.country.name"
                    data-test="owner-form-country-name"
                    :margin="false"
                    placeholder="Land"
                    disabled
                  />
                </div>
              </div>
              <basic-tel-input
                id="mobile"
                v-model="order.owner.mobile"
                data-test="owner-form-mobile"
                :columns="{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                }"
                :horizontal="true"
                label="Mobiltelefonnummer"
                :required="!order.owner.email"
              />
              <basic-input
                id="phone"
                v-model="order.owner.phone"
                data-test="owner-form-phone"
                :columns="{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                }"
                placeholder="Festnetznummer"
                :horizontal="true"
                label="Festnetznummer"
              />
              <basic-input
                id="email"
                v-model="order.owner.email"
                data-test="owner-form-email"
                :columns="{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                }"
                placeholder="E-Mail"
                :horizontal="true"
                label="E-Mail"
                :required="!order.owner.email"
              />
              <basic-datepicker
                id="birthday"
                v-model="order.owner.birthday"
                data-test="owner-form-birthday"
                :disabled="extend"
                :columns="{
                  label: ['col-12', 'col-md-4'],
                  input: ['col-12', 'col-md-8'],
                }"
                placeholder="TT.MM.JJJJ"
                :horizontal="true"
                label="Geburtstag"
              />
            </div>
          </transition>
        </form>
      </template>
      <template v-if="customerKind === 'newCustomer'">
        <form
          novalidate
          @submit.prevent
        >
          <CustomerForm
            :customer="customer"
            :is-new-customer="customerKind === 'newCustomer'"
          />
          <ContactForm
            :customer="customer"
          />
        </form>
      </template>
      <Hardknockout
        v-if="isHardknockoutGEBDAT"
        field-name="Geburtstag"
      />
      <Hardknockout
        v-if="isHardknockoutNeuabschlussGesperrt"
        message="Der Neuabschluss von Verträgen ist für diesen Kunden gesperrt"
      />
      <template v-if="$can('seeSanctionCheckResults')">
        <Ueberpruefung
          v-if="isUberpruefungSanctions"
          message="Der Kunde befindet sich auf der Sanktionsliste der EU"
        />
        <BasicCheckbox
          v-if="hasSanctions"
          id="overwriteSanctions"
          v-model="order._overwriteSanctions"
          name="Sanktionen überprüfen"
          :required="order.hsOrderType === 'ANFRAGE'"
          label="Ich habe den Sanktionslisteneintrag für den Kunden manuell überprüft, und bestätige hiermit, dass es sich  bei dem Ergebnis um eine Verwechslung handeln muss."
          class="mb-4"
        />
      </template>
    </template>
  </ca-card>
</template>

<script>
import BasicInput from '@/components/BaseComponents/BasicInput.vue'
import BasicSelect from '@/components/BaseComponents/BasicSelect.vue'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker.vue'
import BasicTypeahead from '@/components/BaseComponents/BasicTypeahead.vue'
import BasicTelInput from '@/components/BaseComponents/BasicTelInput.vue'
import CaCard from '@/components/Card'
import TitleSelectMixin from '@/components/Forms/title-select-mixin.js'
import getCountryName from '@/helpers/getCountryName.js'
import Hardknockout from './Hardknockout.vue'
import moment from 'moment'
import BasicRadio from '@/components/BaseComponents/BasicRadio.vue'
import CustomerForm from '../Customer/CustomerForm.vue'
import ContactForm from '../Customer/ContactForm.vue'
import getFullNameFromCustomer from '@/resources/helpers/getFullNameFromCustomer.js'
import feathers from '@/api'
import Ueberpruefung from './Ueberpruefung.vue'
import debounce from 'lodash.debounce'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox.vue'
import customerKindEnum from '@/resources/enums/customerKindEnum.js'

export default {
  inject: ['$validator'],
  name: 'OwnerForm',
  components: {
    BasicInput,
    BasicSelect,
    CaCard,
    BasicDatepicker,
    BasicTypeahead,
    BasicTelInput,
    Hardknockout,
    BasicRadio,
    CustomerForm,
    ContactForm,
    Ueberpruefung,
    BasicCheckbox
  },
  mixins: [TitleSelectMixin],
  props: {
    title: {
      type: String,
      default: 'Kunde'
    },
    order: {
      type: Object,
      required: true
    },
    extend: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      customer: '',
      customOwner: false,
      customerKind: 'oldCustomer',
      optionsNeuanlage: [
        { text: 'Bestandskunde', value: 'oldCustomer', id: 'new-customer-yes' },
        { text: 'Neukunde', value: 'newCustomer', id: 'new-customer-no' }
      ],
      moment,
      getFullNameFromCustomer,
      hasSanctions: false
    }
  },
  computed: {
    isCompany () {
      return this.customer.name?.ANR === 'f' || this.customer.kind === 'FIRMA'
    },
    customerSelected () {
      return this.customer && this.order.customerId
    },
    isHardknockoutGEBDAT () {
      if (!this.customer?.misc?.GEBDAT) return false
      const referenceDate = moment().startOf('day').subtract(18, 'years')
      return moment(this.customer.misc.GEBDAT).isAfter(referenceDate)
    },
    isSomeHardknockouts () {
      return this.isHardknockoutGEBDAT
    },
    isHardknockoutNeuabschlussGesperrt () {
      return this.customer && this.customer.neuabschlussGesperrt
    },
    isUberpruefungSanctions () {
      return this.customer && this.hasSanctions && !this.order._overwriteSanctions
    },
    isSomeUeberpruefung () {
      return this.isUberpruefungSanctions
    }
  },
  watch: {
    isSomeHardknockouts: {
      handler: function (newVal) {
        this.$emit('hardknockout-change', newVal)
      }
    },
    isSomeUeberpruefung: {
      handler: function (newVal) {
        this.$emit('ueberpruefung-change', newVal)
      }
    },
    customerKind: {
      handler: function (newVal) {
        if (newVal === 'newCustomer') {
          this.customer = new this.$FeathersVuex.api.Customer()
          this.$set(this.customer, 'kind', 'PERSON')
          this.$emit('setCustomer', this.customer)
          this.$set(this.order, 'customerId', null)
          if (!this.customer.misc) this.$set(this.customer, 'misc', {})
        } else {
          this.customer = ''
        }
      }
    },
    customer: {
      deep: true,
      handler: function (newVal) {
        if ((newVal.name.NAM)) {
          this.debounceCheckIfCustomerHasSanctions()
        }
      }
    }
  },
  async created () {
    if (this.order.customerId) {
      this.customer = await this.$store.dispatch('customer/get', this.order.customerId)
      if (!this.customer.misc) this.$set(this.customer, 'misc', {})
    }
    if (this.order.overwriteSanctions === undefined) {
      this.$set(this.order, '_overwriteSanctions', false)
    }
  },
  methods: {
    searchCityByZip (zip) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          zip: { $regex: '^' + zip, $options: 'i' }
        }
      })
    },
    searchCityByName (name) {
      return this.$store.dispatch('zipcodes/find', {
        query: {
          $limit: 50,
          name: { $regex: '^' + name, $options: 'i' }
        }
      })
    },
    cityResultFormatter (city) {
      return `${city.zip} ${city.name}`
    },
    setCity (city) {
      this.order.owner.city = {
        ...city
      }
    },
    searchCountry (char) {
      return this.$store.dispatch('countries/find', {
        query: {
          $limit: 10,
          char: char.toUpperCase()
        }
      })
    },
    countryResultFormatter (country) {
      return `${country.char} ${country.name}`
    },
    setCountry (country) {
      this.order.owner.country = {
        ...country
      }
    },
    async searchCustomerByName (name) {
      const terms = name.split(' ')
      const searchAllTerms = {
        // every part of the search(name), has to be in name.NAM or in name.VOR
        $and: terms.map(term => ({
          $or: [
            { 'name.NAM': { $regex: term, $options: 'i' } },
            { 'name.VOR': { $regex: term, $options: 'i' } }
          ]
        }))
      }
      const customer = await this.$store.dispatch('customer/find', {
        query: {
          $limit: 25,
          ...searchAllTerms
        }
      })

      return customer
    },
    customerFormatter (customer) {
      if (!customer.name) return customer
      return (customer.name.ANR === 'f' || customer.kind === 'FIRMA')
        ? [customer.name.NAM, customer.address.ZUSATZ1, customer.address.ZUSATZ2, customer.address.ZUSATZ3].filter(p => p).join(' ')
        : `${customer.name.VOR} ${customer.name.NAM}`
    },
    setCustomer (customer) {
      this.order.customerId = customer._id
      this.$emit('setCustomer', customer)
    },
    delCustomer () {
      delete this.order.customerId
    },
    getCountryName,
    debounceCheckIfCustomerHasSanctions: debounce(async function () {
      this.$set(this.order, '_overwriteSanctions', false)
      const query = {
        query: {
          name: [this.customer.name.NAM, this.customer.name.VOR].filter(name => name).join(' ')
        }
      }
      if (this.customer.misc.GEBDAT) query.query.birthday = this.customer.misc.GEBDAT
      if (this.customer.kind) query.query.type = customerKindEnum[this.customer.kind].euSanctions
      this.hasSanctions = await feathers.service('check-for-sanctions').find(query)
    }, 200)
  }
}
</script>
