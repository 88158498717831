<template>
  <div>
    <order-modal
      v-if="savedOrder"
      :show="showOrderModal"
      :order="savedOrder"
      @close="closeModal"
    />
    <ca-header
      heading="Verlängern"
      :column-layout="columnLayout"
      show-column-layout
      @columnLayout="columnLayout = $event"
    >
      <template #buttonSpace>
        <submit-buttons
          extend
          :pending="savePending"
          @save="save"
        />
      </template>
      <template #alert>
        <ca-alert
          v-if="!!saveErrorMessage"
          variant="danger"
          :message="saveErrorMessage"
          @close="saveErrorMessage = null"
        />
        <ca-alert
          v-if="showFormInvalidHint"
          variant="info"
          message="Es sind noch nicht alle erforderlichen Felder ausgefüllt"
          @close="showFormInvalidHint = null"
        />
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <div
        v-if="!loading"
        class="row"
      >
        <div
          class="col-12"
          :class="{'col-xl-8 col-xxl-6': !columnLayout}"
        >
          <div class="row">
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <gvc-form
                ref="gvcForm"
                class="mb-3"
                extend
                :order="order"
              />
              <owner-form
                class="mb-3"
                extend
                :order="order"
              />
              <miscellaneous-form
                class="mb-3"
                extend
                :order="order"
              />
            </div>
            <div
              class="col-12"
              :class="{'col-lg-6': columnLayout}"
            >
              <car-form
                class="mb-3"
                extend
                :car="order.car"
              />
              <product-form
                ref="productForm"
                class="mb-3"
                extend
                :order="order"
                :rates="rates"
              />
            </div>
          </div>

          <div
            v-if="!loading"
            class="form-row justify-content-end"
          >
            <submit-buttons
              extend
              :pending="savePending"
              @save="save"
            />
          </div>
        </div>
        <dev-info :data="{order}" />
      </div>
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import DevInfo from '@/components/DevInfo'
import GvcForm from '@/components/Forms/Order/GvcForm'
import OwnerForm from '@/components/Forms/Order/OwnerForm'
import CarForm from '@/components/Forms/Order/CarForm'
import MiscellaneousForm from '@/components/Forms/Order/MiscellaneousForm'
import ProductForm from '@/components/Forms/Order/ProductForm'
import SubmitButtons from '@/components/Forms/Order/SubmitButtons'
import OrderModal from '@/components/Modals/OrderModal'
import moment from 'moment'
import feathers from '../../api'
import debounce from 'lodash.debounce'

export default {
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  name: 'OrderExtend',
  components: {
    DevInfo,
    OwnerForm,
    CarForm,
    MiscellaneousForm,
    ProductForm,
    SubmitButtons,
    GvcForm,
    OrderModal,
    CaHeader
  },
  data () {
    return {
      loading: false,
      error: null,
      columnLayout: false,
      showOrderModal: false,
      ancestorOrder: null,
      savedOrder: null,
      order: null,
      rates: [],
      ratesPending: false,
      savePending: false,
      saveError: null,
      saveErrorMessage: null,
      showFormInvalidHint: null,
      extendIsAllowed: false
    }
  },
  computed: {
    me () {
      return this.$store.getters['auth/user'].user
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    },
    'order.car.kw': {
      handler: 'updateRates'
    },
    'order.car.km': {
      handler: 'updateRates'
    },
    'order.car.monthFromRegistrationDate': {
      handler: 'updateRates'
    },
    'order.product.rate': {
      handler: 'fetchExtendAllow'
    }
  },
  methods: {
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.savePending) {
          return
        }
        if (!valid) {
          this.showFormInvalidHint = true
          return
        }
        this.savePending = true
        this.saveError = null
        this.saveErrorMessage = null
        this.saveSuccess = false
        this.showFormInvalidHint = false

        this.order.extendGvc = this.$refs.gvcForm.gvc
        this.order.extendGvcFileId = this.$refs.gvcForm.gvc.pdfFileId
        this.order.isContract = this.extendIsAllowed
        this.order.status = this.extendIsAllowed ? 1 : 0

        this.savedOrder = this.order
        this.showOrderModal = true
        this.savedOrder = await this.$store.dispatch('orders/create', [this.order, { query: { $client: { $isExtend: true } } }])
      } catch (error) {
        console.error(error)
        let msg = ''
        switch (error.message) {
        case 'Authentication token missing.': msg = 'Sie sind nicht mehr eingeloggt. Loggen Sie sich neu ein'; break
        case 'Das Erstellen von Policen/Anfragen ist für diesen Account gesperrt': msg = error.message; break
        default: msg = 'Der Server konnte die Anfrage nicht bearbeiten'
        }
        this.saveErrorMessage = msg
        this.saveError = error
      } finally {
        this.savePending = false
      }
    },
    async fetchData () {
      try {
        this.loading = true
        this.ancestorOrder = await this.$store.dispatch('orders/get', this.$route.params.orderId)
        this.rates = this.ancestorOrder.extendRates
        this.order = {
          owner: this.ancestorOrder.owner,
          contract: {
            ancestorWarrantyNumber: this.ancestorOrder.contract.warrantyNumber,
            applicationDate: moment()
          },
          car: this.ancestorOrder.car,
          product: {
            beginContractDate: moment(this.ancestorOrder.product.endContractDate).add(1, 'days'),
            extendableUntil: this.ancestorOrder.product.extendableUntil
          },
          ancestorId: this.ancestorOrder._id,
          gvcs: [],
          notification: true
        }
        this.order.car.km = null
        this.order.car.milageMeasureDate = null
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    },
    debouncedFindRates: debounce(async function () {
      await this.findRates()
      this.ratesPending = false
    }, 500),
    updateRates () {
      this.rates = []
      if (this.$refs.productForm) {
        this.$refs.productForm.reset()
      }
      this.ratesPending = true
      this.debouncedFindRates()
    },
    async findRates () {
      const requiredValuesSet = [this.order.car.kw, this.order.car.km, this.order.car.monthFromRegistrationDate]
        .every(value => value !== null && value !== undefined && value !== '')
      if (requiredValuesSet) {
        this.rates = this.ancestorOrder.product.rate.extendRates
          .filter(extendRate => extendRate.kmVon <= this.order.car.km && extendRate.kmBis >= this.order.car.km &&
            extendRate.alterVon <= this.order.car.monthFromRegistrationDate && extendRate.alterBis >= this.order.car.monthFromRegistrationDate &&
            moment(this.order.product.extendableUntil).diff(moment(this.order.product.beginContractDate).add(extendRate.laufzeit, 'months').add(-1, 'days'), 'days') >= 0
          )
          .sort((a, b) => a.product.hn > b.product.hn ? 1 : -1)
      }
    },
    async closeModal () {
      await this.$router.push({ name: 'OrderDetail', params: { orderId: this.savedOrder._id } })
      this.showOrderModal = false
    },
    async fetchExtendAllow () {
      try {
        const query = {
          chassiNumber: this.order.car.chassi,
          ancestorId: this.order.ancestorId,
          rateTypes: [this.order.product.rate.rateType],
          isKaufschutz: this.order.isKaufschutz
        }
        this.extendIsAllowed = await feathers.service('check-chassi-number').get(query)
      } catch (error) {
        this.error = error
        console.error(error)
      }
    }
  }
}
</script>

<style scoped lang="scss">

.order-info{
  position: sticky;
  top: 70px;
}

</style>
