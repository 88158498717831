<template>
  <ca-card class="mb-3">
    <template #header>
      <div class="row">
        <div class="col">
          <h4 class="mb-0">
            Garantie Vorsorge Check <span v-if="extend">- Nicht älter als 6 Wochen</span>
            <SignedDownloadLink
              v-if="client && client.gvcFile"
              :file="client.gvcFile"
            >
              <template #default="{link}">
                <a
                  :download="client.gvcFile.name"
                  data-test="gvc-download"
                  :href="link"
                  title="GVC herunterladen"
                  class="btn btn-primary btn-sm float-sm-right"
                >
                  GVC Formular <i class="fa fa-download" />
                </a>
              </template>
            </SignedDownloadLink>
          </h4>
        </div>
      </div>
    </template>
    <template #body>
      <template v-if="!extend">
        <div
          v-for="gvc of order.gvcs"
          :key="gvc._id"
          class="row mb-2"
        >
          <div class="col-4">
            <span v-if="!edit">
              {{ gvc.date.start | formatDate }} - {{ gvc.date.end | formatDate }}
            </span>
            <span v-else>
              <BasicDatepicker
                v-model="gvc.date.end"
                @input="setGvcStart(gvc.date)"
              />
            </span>
          </div>
          <div
            class="col"
          >
            <span
              v-if="!edit"
              class="badge badge-pill text-gray-5"
              :class="getGvcClass(gvc.status)"
            >{{ gvc.status | formatGvcStatus }}</span>
            <select
              v-else
              id="saison-char-from"
              v-model="gvc.status"
              class="form-control"
            >
              <option value="0">
                Geplant
              </option>
              <option value="1">
                Ausstehend
              </option>
              <option value="3">
                Abgeschlossen
              </option>
              <option value="4">
                Abgelehnt
              </option>
              <option value="5">
                Abgelaufen
              </option>
            </select>
          </div>
          <span
            v-if="gvc.status > 1 && gvc.status < 4"
            class="col-auto"
            title="Eingabedatum"
          >
            {{ gvc.entryDate | formatDate }}
          </span>
          <div class="col-auto">
            <button
              v-if="gvc.status==2 && user.role==10"
              type="button"
              title="GVC ablehnen"
              (click)="declineGVC(i)"
              class="btn btn-danger btn-sm float-sm-right"
            >
              <i class="fa fa-times" />
            </button>
            <button
              v-if="gvc.status==2 && user.role==10"
              type="button"
              title="GVC akzeptieren"
              class="btn btn-success btn-sm float-sm-right"
            >
              <i class="fa fa-check" />
            </button>
            <SignedDownloadLink
              v-if="gvc.pdfFile"
              :file="gvc.pdfFile"
            >
              <template #default="{link}">
                <a
                  :download="gvc.pdfFile.name"
                  :href="link"
                  title="GVC herunterladen"
                  class="btn btn-primary btn-sm float-sm-right"
                >
                  <i class="fa fa-download" />
                </a>
              </template>
            </SignedDownloadLink>
          </div>
        </div>
      </template>
      <div
        v-if="(extend || enterGvc) && !edit"
        class="row"
      >
        <div class="col-6">
          <basic-datepicker
            v-model="gvc.entryDate"
            data-test="gvc-form-entry-date"
            :disabled="!extend && !enterGvc"
            name="GVC Datum"
            placeholder="TT.MM.JJJJ"
            required
          />
        </div>
        <div class="col-6">
          <basic-file-upload
            v-model="gvc.pdfFile"
            required
            data-test="gvc-form-pdf-file"
            :disabled="!extend && !enterGvc"
            placeholder="GVC Formular hochladen"
            name="GVC Formular"
            accept=".pdf"
            small="Erlaubt sind PDFs"
            @uploaded="file => gvc.pdfFileId = file._id"
            @removed="gvc.pdfFileId = null"
          />
        </div>
      </div>
      <div
        class="row"
      >
        <div
          v-if="nextGvc"
          class="col-12"
        >
          <small>Nächster Vorsorgetermin: {{ nextGvc.date.start | formatDate }} - {{ nextGvc.date.end | formatDate }}</small>
        </div>
      </div>
      <div
        v-if="!extend && enterGvc && !edit"
        class="row justify-content-end"
      >
        <div class="col-auto">
          <button
            class="btn btn-success text-white"
            @click="submitGvc"
          >
            GVC eintragen
          </button>
        </div>
      </div>
    </template>
  </ca-card>
</template>

<script>
import CaCard from '@/components/Card'
import BasicDatepicker from '@/components/BaseComponents/BasicDatepicker'
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload'
import SignedDownloadLink from '@/components/SignedDownloadLink'
import formatDate from '../../../filters/formatDate.js'
import formatGvcStatus from '../../../filters/formatGvcStatus'
import moment from 'moment'
export default {
  inject: ['$validator'],
  name: 'GvcForm',
  components: {
    BasicDatepicker,
    BasicFileUpload,
    SignedDownloadLink,
    CaCard
  },
  filters: {
    formatDate,
    formatGvcStatus
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    extend: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    gvc: {},
    client: null
  }),
  computed: {
    user () {
      return this.$store.getters['auth/user'].user
    },
    nextGvc () {
      if (!this.order.gvcs || this.order.gvcs.length === 0) return
      return this.order.gvcs
        .filter(gvc => gvc.status < 2)
        .reduce((prevClosestGVC, gvc) => {
          const monthsTillGVC = moment(gvc.date.end).diff(moment(), 'days', false)
          if ((prevClosestGVC == null || monthsTillGVC < prevClosestGVC) && monthsTillGVC >= 0) {
            return gvc
          } else {
            return prevClosestGVC
          }
        }, null)
    },
    enterGvc () {
      return this.nextGvc && this.nextGvc.status === 1
    }
  },
  async created () {
    const clientReq = await this.$store.dispatch('clients/find', {
      query: {
        $limit: 1
      }
    })
    this.client = clientReq.data[0]
  },
  methods: {
    getGvcClass (gvcStatus) {
      switch (gvcStatus) {
      case 0: return 'badge-default'
      case 1: return 'badge-success'
      case 2:
      case 3:
      case 4: return 'badge-warning'
      case 5: return 'text-muted'
      default: return ''
      }
    },
    setGvcStart (date) {
      this.$set(date, 'start', moment(date.end).subtract(4, 'weeks').toDate())
    },
    async submitGvc () {
      try {
        const valid = await this.$validator.validateAll()
        if (this.gvcSubmitPending || !valid) {
          return
        }
        this.gvcSubmitPending = true
        this.gvcSubmitError = null
        this.gvcSubmitSuccess = false
        this.showFormInvalidHint = false
        const patchedGvc = await this.$store.dispatch('gvcs/patch', [this.nextGvc._id, {
          ...this.gvc,
          status: 3
        }])

        const index = this.order.gvcs.findIndex(gvc => gvc._id === patchedGvc._id)
        this.$set(this.order.gvcs, index, patchedGvc)

        this.gvcSubmitSuccess = true
      } catch (error) {
        console.log(error)
        this.gvcSubmitError = error
      } finally {
        this.gvcSubmitPending = false
      }
    }
  }
}
</script>
