<template>
  <CaCard class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Kontaktdaten
      </h4>
    </template>
    <template #body>
      <form
        v-if="customer.contact"
        novalidate
        autocomplete="off"
        @submit.prevent
      >
        <BasicInput
          v-if="!isCompany"
          id="telpriv"
          v-model="customer.contact.TEL"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Telefonnummer"
          :horizontal="true"
          label="Telefonnummer"
        />

        <BasicInput
          v-else
          id="telpriv"
          v-model="customer.contact.TEF"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Telefonnummer"
          :horizontal="true"
          label="Telefonnummer"
        />

        <BasicInput
          id="telfax"
          v-model="customer.contact.FAX"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Telefaxnummer"
          :horizontal="true"
          label="Telefax"
        />

        <BasicInput
          id="mobil"
          v-model="customer.contact.MOB"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Mobiltelefonnummer"
          :horizontal="true"
          label="Mobiltelefon"
        />

        <BasicInput
          id="mailprivat"
          v-model="customer.contact.MAIL"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="E-Mail Adresse"
          :horizontal="true"
          label="E-Mail"
          required
          type="email"
        />

        <BasicInput
          id="urladdress"
          v-model="customer.misc.URL"
          :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
          placeholder="Website-URL"
          :horizontal="true"
          label="Website"
        />
      </form>
    </template>
  </CaCard>
</template>

<script>
import CaCard from '@/components/Card.vue'
import BasicInput from '@/components/BaseComponents/BasicInput.vue'

export default {
  components: {
    CaCard,
    BasicInput
  },
  props: {
    customer: {
      type: Object,
      required: true
    }
  },
  computed: {
    isCompany () {
      return this.customer.name?.ANR === 'f'
    }
  }
}
</script>
