<template>
  <ca-card class="mb-3">
    <template #header>
      <h4 class="mb-0">
        Sonstiges
      </h4>
    </template>
    <template #body>
      <template v-if="order.faaren">
        <div

          class="form-row my-3"
        >
          <div class="col-12 col-md-4">
            Partner ID
          </div>
          <div class="col-12 col-md-8">
            {{ order.faaren.partnerId }}
          </div>
        </div>
        <div

          class="form-row my-3"
        >
          <div class="col-12 col-md-4">
            Fahrzeug ID
          </div>
          <div class="col-12 col-md-8">
            {{ order.faaren.carId }}
          </div>
        </div>
      </template>
      <basic-file-upload
        v-model="order.attachmentFiles"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        placeholder="Anhang hochladen"
        :horizontal="true"
        multiple
        label="Anhang hochladen (z.B. Übergabeprotokoll)"
        accept="image/*,.pdf"
        small="Erlaubt sind PDF, Bilder etc..."
        :align-label-center="false"
        @uploaded="setAttachments"
        @removed="removeAttachment"
      />
      <basic-textarea
        v-model="order.contract.comment"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        placeholder="Hinweistext"
        rows="3"
        :horizontal="true"
        label="Hinweis"
        :align-label-center="false"
      />
    </template>
  </ca-card>
</template>

<script>
import BasicFileUpload from '@/components/BaseComponents/BasicFileUpload'
import BasicTextarea from '@/components/BaseComponents/BasicTextarea'
import CaCard from '@/components/Card'

export default {
  inject: ['$validator'],
  name: 'MiscellaneousForm',
  components: {
    BasicFileUpload,
    BasicTextarea,
    CaCard
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  },
  methods: {
    setAttachments (files) {
      if (!this.order.attachmentFileIds) this.$set(this.order, 'attachmentFileIds', [])
      if (!this.order.attachmentFiles) this.$set(this.order, 'attachmentFiles', [])
      this.order.attachmentFileIds.push(...files.map(file => file._id))
    },
    removeAttachment (index) {
      this.order.attachmentFileIds.splice(index, 1)
    }
  }
}
</script>
