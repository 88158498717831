<template>
  <div class="bg-warning-light text-warning py-1 px-2 rounded">
    <h5 class="mb-0">
      Achtung
    </h5>
    <template v-if="fieldName">
      Wegen des eingegebenen Wertes zu dem Feld "{{ fieldName }}", können Sie nur eine Anfrage senden.
    </template>
    <template v-else>
      {{ message }}
    </template>
  </div>
</template>
<script>
export default {
  props: {
    message: {
      type: String,
      default: 'Gern überprüfen wir die Versicherbarkeit als Liebhaberfahrzeug. Nach Eingabe aller benötigten Informationen können Sie uns hierzu eine Anfrage senden.'
    },
    fieldName: {
      type: String,
      default: null
    }
  }
}
</script>
