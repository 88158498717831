<template>
  <div>
    <template v-if="!edit && !extend">
      <button
        class="btn btn-primary mr-2"
        :disabled="orderCreationType !== 'request'"
        @click="save('request')"
      >
        Anfrage senden <i
          v-if="pending"
          class="fas fa-circle-notch fa-spin"
        />
      </button>
      <div
        :id="popoverParentId"
        class="button-wrapper"
      >
        <button
          class="btn btn-success text-white"
          :disabled="orderCreationType !== 'contract'"
          @click="save('contract')"
        >
          Vertrag abschließen <i
            v-if="pending"
            class="fas fa-circle-notch fa-spin"
          />
        </button>
      </div>

      <b-popover
        v-if="showPopover && !(orderCreationType === 'contract')"
        :target="popoverParentId"
        title="Vertragsabschluss für Fahrzeug nicht möglich"
        :placement="popoverPlacement"
        triggers="hover"
        content="Bitte erstellen Sie eine Anfrage."
      />
    </template>
    <template v-else-if="edit">
      <button
        class="btn btn-success text-white"
        @click="save()"
      >
        Speichern <i
          v-if="pending"
          class="fa fa-circle-notch fa-spin"
        />
      </button>
    </template>
    <!-- <template v-else-if="extend">
      <button
        class="btn btn-success text-white"
        @click="save()"
      >
        Verlängerung erstellen <i
          v-if="pending"
          class="fas fa-circle-notch fa-spin"
        />
      </button>
    </template> -->
  </div>
</template>

<script>
export default {
  name: 'SubmitButtons',
  props: {
    orderCreationType: {
      type: String,
      default: null
    },
    pending: {
      type: Boolean,
      default: false
    },
    edit: {
      type: Boolean,
      default: false
    },
    extend: {
      type: Boolean,
      default: false
    },
    showPopover: {
      type: Boolean,
      default: false
    },
    popoverPlacement: {
      type: String,
      default: 'top'
    },
    popoverParentId: {
      type: String,
      default: 'popover-parent'
    }
  },
  methods: {
    save (orderType) {
      this.$emit('save', orderType)
    }
  }
}
</script>
<style scoped lang="scss">

.button-wrapper{
  display: inline-block;
}

</style>
