<template>
  <div
    v-if="order.product.rate && order.product.rate.rateType === 'TAXI'"
  >
    <basic-checkbox
      v-model="order.product.rechnungHalter"
      :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
      :horizontal="true"
      label="Rechnung an Halter"
    />
    <template v-if="order.car.monthFromRegistrationDate <= 12 ">
      <basic-checkbox
        v-model="order.product.ratenZahlung"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        :horizontal="true"
        label="Ratenzahlung"
      />
      <basic-select
        v-if="order.product.ratenZahlung"
        v-model="order.product.raten"
        :columns="{label: ['col-12', 'col-md-4'], input:['col-12', 'col-md-8']}"
        placeholder="bitte wählen"
        :horizontal="true"
        label="Anzahl Raten"
        required
        :options="[
          {label: '3 Raten', value: '3'},
          {label: '6 Raten', value: '6'},
          {label: '9 Raten', value: '9'},
          {label: '12 Raten', value: '12'},
        ]"
      />
    </template>
  </div>
</template>

<script>
import BasicSelect from '@/components/BaseComponents/BasicSelect'
import BasicCheckbox from '@/components/BaseComponents/BasicCheckbox'

export default {
  inject: ['$validator'],
  name: 'TaxiForm',
  components: {
    BasicSelect,
    BasicCheckbox
  },
  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
